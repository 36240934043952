import Layout from 'components/Layout';
import Wrapper from 'components/Wrapper';
import Item from 'components/Item';
import Editor from 'components/Editor';
import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import SEO from 'utils/SEO';
import allPosts from 'data/allPosts';
import LazyLoad from 'react-lazy-load';

const StyledWrapper = styled.div`
	.post {
		&__wrapper {
			background: ${theme.colors.background[0]};
			max-width: 768px;
			margin: 0 auto;
			padding: ${theme.size(6)};
			margin-bottom: ${theme.size(6)};
			border-radius: ${theme.size(1)};
			a > * {
				grid-template-columns: 1fr 1fr;
			}
			a {
				display: block;
				margin-bottom: ${theme.size(2)};
			}
		}
		&__submission {
			background: ${theme.colors.background[1]};
			padding: ${theme.size(6)};
			border-radius: ${theme.size(1)};
			margin-top: ${theme.size(4)};
			a > * {
				grid-template-columns: 1fr 2fr;
			}
		}
	}
`;

allPosts.map((post, index) => {
	post._submissions = post._submissions.map((submission, index) => {
		submission.slug = `${post.slug}/${submission.slug}`;
		return submission;
	});
	return post;
});

export default props => {
	return (
		<Layout>
			<SEO />
			<Wrapper>
				<StyledWrapper>
					{allPosts.map((post, index) => (
						<div className="post__wrapper" key={index}>
							<div>
								<Item
									data={post}
									image={'landscape'}
									layout="horizontal"
									subtitle={false}
									lazyload={true}
								/>
								<LazyLoad offset={0} debounce={false}>
									<Editor id={post.id}></Editor>
								</LazyLoad>
							</div>
							<div>
								{post._submissions.map((submission, index) => (
									<div className="post__submission" key={index}>
										<Item
											data={submission}
											image={'square'}
											layout="horizontal"
											subtitle={false}
											lazyload={true}
										/>
										<LazyLoad offset={0} debounce={false}>
											<Editor id={submission.id}></Editor>
										</LazyLoad>
									</div>
								))}
							</div>
						</div>
					))}
				</StyledWrapper>
			</Wrapper>
		</Layout>
	);
};
